@import url("./fonts.css");
@import url("./colors.css");
@import url("./reset.css");

body {
  color: var(--text-color);
  background: var(--background-color);
}

.content {
  width: 1150px;
  margin: 0 auto;
  font-family: BoschSans-Regular;
}

h3 {
  margin-bottom: 1rem;
}

.right-column {
  flex: 1;
  text-align: right;
}

.field-item {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.big-modal-content {
  width: 60%;
  max-width: 50%;
  min-width: 180px;
  background-color: var(--white-color);
  padding: 15px;
  border: 1px solid var(--border-fields);
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 90vh;
}

.modal-content {
  width: 85%;
  max-width: 500px;
  min-width: 180px;
  background-color: var(--white-color);
  padding: 15px;
  border: 1px solid var(--border-fields);
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 70vh;
}

.modal-content-first {
  width: 120%;
  max-width: 500px;
  min-width: 180px;
  background-color: var(--white-color);
  padding: 15px;
  border: 1px solid var(--border-fields);
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 90vh;
}

.modal-content:only-child {
  overflow-y: scroll;
}

.big-modal-content:only-child {
  overflow-y: scroll;
}

.card-generator {
  height: 5rem;
  padding: 1rem 1.5rem;
  border-radius: 5px;
  border: 1px solid var(--border-fields);
  box-shadow: 1px 2px 8px var(--box-shadow-color);
  justify-content: space-between;
  margin-bottom: 1rem;
}

.card-generator:hover {
  cursor: pointer;
  background-color: var(--extra-grey-light);
}

form .buttons-section {
  justify-content: end;
  gap: 1rem;
}

.info-text {
  color: var(--secondary-text-color);
}

.empty-slot {
  display: flex;
  flex-direction: column;

  margin: 0.5rem;
  padding: 2rem 1rem;

  border-radius: 5px;
  align-items: center;
  justify-content: center;

  background-color: var(--extra-grey-light);
  color: var(--extra-grey-medium);
}

.recomendation-slot {
  display: flex;
  flex-direction: column;
  height: 57%;

  margin: 1rem;
  margin-left: 0.5rem;
  margin-right: 1rem;
  padding: 2rem 2.2rem;

  border-radius: 5px;
  align-items: center;
  justify-content: center;

  background-color: var(--bosch-warning-yellow-opacity10);
  color: var(--black-color);
}

.horizontal-flex-container {
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: flex-start;
  align-items: flex-start;
}

.fixed-div {
  width: 48%;
  min-width: 300px;
  max-width: 500px;
  box-sizing: border-box;
}

.horizontal-flex-responsible {
  flex: 1;
  border: 1px solid var(--border-fields);
  box-shadow: 1px 2px 8px var(--box-shadow-color);
  padding: 15px;
}

.multiple-horizontal-slots {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1015px) {
  .horizontal-flex-responsible {
    flex: 0;
    border: 1px solid var(--border-fields);
    box-shadow: 1px 2px 8px var(--box-shadow-color);
    padding: 15px;
  }

  .multiple-horizontal-slots {
    display: flex;
    flex-direction: column;
  }
}

.generic-approve-card {
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid var(--border-fields);
  box-shadow: 1px 2px 8px var(--box-shadow-color);
}

.generic-approve-card:hover {
  cursor: pointer;
  background-color: var(--extra-grey-light);
}

@media (max-width: 850px) {
  .generic-approve-card {
    flex-direction: column !important;
  }

  .generic-approve-card-children {
    width: 100% !important;
  }

  .generic-approve-card-buttons {
    margin-top: 1rem;
    justify-content: end;
  }
}

.resume-material-collect {
  padding: 1rem;
  border: 1px solid var(--border-fields);
  border-radius: 5px;
  box-shadow: 1px 2px 8px var(--box-shadow-color);
}

.pill-tag {
  padding: 0.5rem;
  font-size: 14px;
  font-weight: bold;
  border-radius: 1rem;
  background-color: var(--extra-grey-light);
  color: var(--extra-grey);
}

.card-dealer {
  width: 100%;
  padding: 1rem 1.5rem;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid var(--border-fields);
  box-shadow: 1px 2px 8px var(--box-shadow-color);
  justify-content: space-between;
  margin-bottom: 1rem;
}

.card-dealer:hover {
  cursor: pointer;
  background-color: var(--extra-grey-light);
}

.card-dealer .left .infos {
  padding: 0.4rem 0;
  gap: 0.5rem;
}

.card-dealer .left img {
  min-height: 5rem;
  margin-right: 1.5rem;
}

.card-dealer .right {
  text-align: end;
  justify-content: space-between;
}

.card-dealer .right .price {
  color: var(--extra-grey);
  font-family: BoschSans-Bold;
  font-size: 22px;
}

.card-dealer .right .type {
  color: var(--secondary-color);
  font-size: 14px;
  font-family: BoschSans-Medium;
}

.gradient-line {
  position: relative;
}

.gradient-line::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: linear-gradient(to right, var(--linear-gradient-bosch-colors));
}

.a-link-style {
  color: var(--bosch-accent-blue);
  cursor: pointer;
}

@media (max-width: 680px) {
  .card-dealer .left img {
    display: none;
  }

  .card-dealer .left .infos {
    margin-right: 2rem;
    font-size: 14px;
  }

  .card-dealer img {
    display: none;
  }

  .card-dealer .infos {
    margin-right: 2rem;
    font-size: 14px;
  }
}

.modal-background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: var(--background-shadow-color);
}

form button {
  margin: 2rem 0rem;
  align-self: flex-end;
  padding: 0rem 2rem;
}

form .form-fields {
  gap: 1rem;
}

form .radio-types {
  align-items: center;
}

form .radio-types label {
  font-size: 16px !important;
}

form label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

form .form-fields .double-fields {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

form .form-fields .double-fields .flex-column {
  width: 100%;
}

form .form-fields h4 {
  margin-top: 2rem;
}

form .form-fields span {
  font-size: 12px;
  margin: 5px 0px;
  color: var(--bosch-error-red);
}

form .form-fields label {
  font-size: 14px;
  margin: 6px;
}

form .form-fields textarea {
  max-width: 97.5%;
  min-width: 97.5%;
}

@media (max-width: 650px) {
  form .form-fields .double-fields {
    flex-direction: column;
  }

  .preview-img {
    width: 100%;
  }
}

.top-line {
  border-top: 1px solid black;
  margin-top: 12px;
}

strong {
  font-family: BoschSans-Bold;
}

.page {
  padding: 0rem 1rem;
}

.page-centered {
  padding: 0rem 1rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: end;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.cursor {
  cursor: pointer;
}

.justify-between {
  justify-content: space-between;
}

spark-table-body spark-table-row:hover {
  color: var(--bosch-accent-blue) !important;
  cursor: pointer;
}

.custom-line-hr {
  width: 100%;
  height: 1px;
  background-color: var(--divider-color);
  margin: 10px 0px;
}

.margin-topbottom-1 {
  margin: 10px 0;
}

.cta-info-component {
  padding: 0.8rem;
  border-radius: 5px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  background-color: var(--bosch-accent-turquoise);
  color: var(--white-color);
}

select {
  min-height: 2.6rem !important;
  border: var(--border-fields) 1px solid;
  padding: 0.5rem;
  border-radius: 8px;
}

select option {
  font-size: 16px;
}

textarea {
  min-height: 1.5rem;
  border: var(--border-fields) 1px solid;
  padding: 0.5rem;
  border-radius: 8px;
}

textarea::placeholder {
  color: var(--placeholder-text-color);
  font-family: BoschSans-Regular;
}

input {
  min-height: 1.5rem;
  border: var(--border-fields) 1px solid;
  padding: 0.5rem;
  border-radius: 8px;
}

input::placeholder {
  color: var(--placeholder-text-color);
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

button {
  font-weight: 600;
  min-height: 2.5rem;
  padding: 0.5rem;
  border-radius: 8px;
  border: 0;
  box-shadow: 1px 1px 10px var(--box-shadow-color);
}

button:hover {
  cursor: pointer;
}

button.neutral-white {
  color: var(--extra-grey);
  background-color: var(--white-color);
  border: 1px solid var(--border-fields);
}

button.primary {
  color: var(--white-color);
  background-color: var(--primary-color);
}

button.blue {
  color: var(--white-color);
  background-color: var(--bosch-accent-blue);
}

.buttonGray {
  cursor: default !important;
}

button.blue-with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;

  color: var(--white-color);
  background-color: var(--bosch-accent-blue);
}

a.blue-with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: var(--white-color);
  background-color: var(--bosch-accent-blue);
}

button.white-with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
}

button.white-with-icon img {
  height: 1.5rem;
}

button.white-with-icon.ml-30 {
  margin-left: 30px;
}

button.white-with-icon.ml-10 {
  margin-left: 10px;
}

button.blue-with-icon img {
  height: 1.5rem;
  margin-right: 0.5rem;
}

button.red {
  color: var(--bosch-error-red);
  background-color: var(--bosch-error-red-opacity10);
}

button.red:hover {
  color: var(--white-color);
  background-color: var(--bosch-error-red);
}

button.green {
  color: var(--bosch-success-green);
  background-color: var(--bosch-success-green-opacity10);
}

button.green:hover {
  color: var(--white-color);
  background-color: var(--bosch-success-green);
}

button:disabled {
  background-color: var(--disabled-color);
}

.break-word-container {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.hide-word-container {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chart-mock {
  width: 98.5%;
  display: flex;
  flex-direction: row;
  background-color: var(--white-color);
  box-shadow: 1px 2px 8px var(--box-shadow-color);
  border-radius: 5px;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
}

.chart-mock img {
  height: 10rem;
}

.card-scrap {
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid var(--border-fields);
  box-shadow: 1px 2px 8px var(--box-shadow-color);
  justify-content: space-between;
  gap: 15px;
}

.card-scrap:hover {
  cursor: pointer;
  background-color: var(--extra-grey-light);
}

.expanded-section-card-scrap {
  padding: 1rem;
  border-radius: 0px 0px 10px 10px;
}

.steps-prospection {
  gap:1;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1190px) {
  .expanded-section-card-scrap {
    height: auto;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 490px) {
  .chart-cards {
    flex-direction: column;
    width: 100%;
  }

  .card-scrap-basic-info {
    flex-direction: column;
    gap: 10px;
  }

  .card-scrap-img {
    display: none;
  }

  .tag {
    align-items: end;
  }

  .tag-fee {
    flex-direction: row;
    gap: 10px !important;
  }

  .tag-fee span {
    font-size: 16px !important;
  }
}

@media (max-width: 1150px) {
  .content {
    width: 100vw;
  }
}

@media (max-width: 950px) {
  .page-centered {
    padding: 0rem 1rem;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.buttons-section {
  justify-content: end;
}

.buttons-section button {
  padding: 0 1.5rem;
}

.edit-section {
  margin: 1rem 0;
  width: 100%;
  justify-content: end;
}

.edit-section a {
  cursor: pointer;
  color: var(--secondary-color);
}

.info-section-warning {
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid var(--border-fields);
}

.info-section {
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid var(--border-fields);
}

.info-section h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.info-section label {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.info-section input {
  font-size: 18px;
  background-color: var(--white-color-5);
}

.info-section textarea {
  max-width: 97.5%;
  min-width: 97.5%;
}

.info-section .title {
  justify-content: space-between;
}

.info-section a {
  color: var(--secondary-color);
  cursor: pointer;
}

.triple-fields {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.double-fields {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.upload-csv {
  background: none;
  box-shadow: none;
  width: fit-content;
  height: fit-content;
  margin: 0;
}

.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-shadow-color);
}

.double-fields .flex-column {
  width: 50%;
}

.tag {
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

.tag-green {
  background-color: var(--light-green);
  color: var(--white-color);
}

@media (max-width: 1160px) {
  .triple-fields {
    width: 100%;
    flex-direction: column;
  }

  .triple-fields .flex-column {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .double-fields {
    flex-direction: column;
  }

  .double-fields .flex-column {
    width: 100%;
  }
}

.close:hover,
.close:focus {
  color: var(--black-color);
  text-decoration: none;
}

.dropdown-menu li:hover {
  background-color: var(--extra-grey-light);
  color: black;
}

.forget-password-title {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10%;
}

.first-access-title {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10%;
}

.dropdown-menu {
  position: absolute;
  background: var(--white-color);
  border: 1px solid var(--extra-grey-light);
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-menu ul {
  max-height: none;
  min-width: 10rem;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 8px 12px;
  cursor: pointer;
}

.icon-column {
  width: 12rem;
}

.icon-column-8rem {
  width: 8rem;
}

.icon-column-4rem {
  width: 4rem;
}

.icon-flag {
  position: absolute;
}

@media (max-width: 650px) {
  form .form-fields .double-fields {
    flex-direction: column;
  }
}

.eccomerce-card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-evenly;
}

.flex-row.align-center {
  display: flex;
  align-items: center;
}

.flex-row.align-centercard {
  display: flex;
  align-items: center;
  margin-left: auto;
  height: 100%;
}

.hidden-label {
  display: none;
  margin-left: 0.5rem;
}

.icon-container {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.icon-container:hover .hidden-label {
  display: block;
}

.select-field {
  font-size: 14px;
}

.login-section {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: end;
  height: 65vh;
}

.login-section .form-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(../assets/img/background-form.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 1050px) {
  .login-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .login-section .form-side {
    width: 100;
    background-image: none;
  }
}

.login-section .form-side form {
  display: flex;
  flex-direction: column;
  width: 20rem;
  gap: 1rem;
}

.login-section .form-side form h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.login-section .form-side form button {
  margin-top: 0;
}

.button-login-non-password {
  width: 100%;
  color: var(--extra-grey);
  background-color: var(--white-color);
  border: 1px solid var(--border-fields);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: center;
}

.forget-password-button .forget-password {
  background: none;
  box-shadow: none;
  width: fit-content;
  margin: 0;
  font-weight: 500;
  cursor: pointer;
}

.login-without-password {
  background: none;
  box-shadow: none;
  width: fit-content;
  height: fit-content;
  margin: 0;
  font-weight: 500;
}

.expiration-main-context {
  width: calc(100% - 32px);
  height: auto;
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--extra-grey-medium);
  padding: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.expiration-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.number-input-button {
  width: 22px;
}

.expiration-input-qtt-days {
  width: calc(95% - 90px);
  margin-left: 5px;
  margin-right: 5px;
  height: 22px;
}

.expiration-wrapper {
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: auto;
}

.expiration-wrapper button {
  margin: 0;
}

.left-wrapper-content {
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
}

.expiration-flex-column {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.input-and-preview {
  gap: 10px;
  display: flex;
  flex-direction: row;
}

.input-and-preview-column {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 1rem;
}

input[type="file"] {
  min-height: 5rem;
  width: 100%;
}

.image-selected {
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid var(--border-fields);
}

.preview-img {
  width: 350px;
  height: auto;
}

.legal-informations {
  padding: 1rem;
  border-radius: 5px;
  background-color: var(--extra-grey-light);
  gap: 5px;
}

.legal-informations p {
  font-size: 14px;
  font-weight: 600;
  color: var(--extra-grey);
}

@media (max-width: 900px) {
  .input-and-preview {
    flex-direction: column;
  }

  input[type="file"] {
    width: auto;
  }
}

.scraps-form-button-save {
  width: 200px;
  height: 35px;
  background-color: #007bc0;
  padding-left: 8px;
  color: white;
}

.scraps-form-save {
  width: calc(100% + 16px);
  justify-content: flex-end;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
}

ul {
  border: 1px solid #ddd;
  list-style: none;
  margin: 0;
  max-height: 150px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
}

li {
  padding: 10px;
  cursor: pointer;
}

li:hover {
  background-color: #007bff;
  color: #fff;
}

.padding-24 {
  padding-left: 24px;
}

.dropzone {
  border: 2px dashed #007bff;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  width: 100%;
  margin: auto;
}

.inputFile {
  display: none;
}

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 5px;
}

.suggestions {
  border: 1px solid #ccc;
  border-top-width: 0;
  list-style: none;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
  margin: 0;
}

.suggestions li {
  padding: 0.5rem;
  cursor: pointer;
}

.suggestions li:hover,
.suggestion-active {
  background-color: #007bff;
  color: white;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.addressContent {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.addressContent input {
  margin-bottom: 12px;
  width: 30%;
}

.numeric-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.numeric-button {
  width: 42px;
}

.numeric-input {
  width: calc(90% - 84px);
}

.picker-select {
  width: 100%;
  margin-top: 16px;
}

.loader-container {
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loader-text {
  margin-right: 12px;
}

.radio-and-pickers-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.scraps-form-radio-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 24px;
}

.radio-option {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.picker-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.picker-item {
  margin-top: 16px;
  width: 30%;
}

.picker-label {
  margin-top: 18px;
  margin-bottom: 6px;
}

.numeric-label {
  margin-top: 32px;
  margin-bottom: 6px;
}

.address-container {
  width: 100%;
  border: 1px solid var(--border-fields);
  padding: 15px;
  margin-top: 24px;
}

.address-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.address-button {
  margin-top: 10px;
}

.address-button-cancel {
  margin-left: 12px;
}

.card-dealer img {
  min-height: 5rem;
  max-height: 95px;
  width: 146px;
  margin-right: 1.5rem;
}

.card-dealer .infos {
  width: 100%;
  padding: 0.4rem 0;
  gap: 0.5rem;
}

.blue-with-icon {
  max-height: 48px;
}

.dashboard-generator {
  width: 100%;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.dashboard-generator .button {
  height: 48px;
  width: 256px;
}

.reset-currency-input-style * {
  border: none;
  border-width: 0;
  background-color: transparent !important;
  pointer-events: none;
}

.no-disabled-style:disabled {
  background-color: white;
  color: black !important;
  opacity: 1;
  cursor: text;
  border: 1px solid #ccc;
}

.collect-card {
  padding: 0.7rem 1rem;
  border: 1px solid var(--border-fields);
  box-shadow: 1px 2px 8px var(--box-shadow-color);
  border-radius: 5px;
}

.collect-card:hover {
  border: 1px solid var(--extra-grey-medium);
  cursor: pointer;
}

.collect-card p {
  font-weight: bold;
}

.tag-value-quantity {
  color: var(--white-color);
  background-color: var(--bosch-accent-turquoise);
  padding: 0.5rem;
  border-radius: 5px;
}

.tag-value-quantity .quantity {
  font-size: 12px;
}

.tag-value-quantity .value {
  font-size: 14px;
}

.item-label {
  color: var(--extra-grey);
  font-size: 14px;
  margin-bottom: 0.4rem;
}

.image-preview {
  border: solid 1px var(--border-fields);
  border-radius: 5px;
  padding: 1rem;
  gap: 15px;
  overflow: auto;
}

.image-preview .remove-image {
  background-color: var(--light-red);
  width: 100%;
  padding: 5px 0px 5px 0px;
  cursor: pointer;
}

.image-preview .image {
  width: 150px;
  height: 150px;
  object-fit: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--white-color);
}

.button-list {
  padding: 0.2rem;
  border-radius: 10px;
  background-color: var(--bosch-error-red-opacity10);
  cursor: pointer;
}

.preview-file-line:hover {
  background-color: var(--extra-grey-light) !important;
  color: var(--black-color);
}
