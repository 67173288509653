.filters {
  background-color: white;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 5;
  box-shadow: 0 -1px 10px rgba(0, 0, 0, .13)
}

.detals-filter {
  width: 160px;
  margin-Bottom: 10px;
}

.container {
  position: absolute;
  z-index: 12;
  width: 170px;
}