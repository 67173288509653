.slider-main {
    border: 1px solid lightgray;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 8px;
    height: 28px;
}

.centered-flex-box {
    width: 105px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }