.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.detals-card {
    display: grid;
    grid-template-columns: 0.05fr 0.05fr 1fr;
    border-radius: 5px;
    align-items: center;
    background-color: white;
    box-shadow: 0 -1px 10px rgba(0, 0, 0, .13);
    font-weight: 700;
    margin: 5px;
    min-width: 10rem;
}

.chart {
    display: flex;
    justify-content: center;
    width: 100%;
    max-height: 15rem;
}

.first-financial {
    min-height: 17rem;
}

.icone {
    height: 100%;
}

.second-card {
    display: flex;
    flex-direction: column;
    padding: 10px 5px;
    align-items: center;
}

.top {
    margin-top: 4px;
}

.img-icon {
    width: 24px;
    height: 24px;
    padding: 7px;
}

.collect {
    background-color: #9E2896;
    color: #9E2896;
}

.Incoming {
    background-color: #007BC0;
    color: #007BC0;
}

.Received {
    background-color: #18837E;
    color: #18837E;
}

.margin {
    margin-right: 10px;
}

.filter {
    display: flex;
    flex-direction: row;
}