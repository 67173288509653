.tooltip-container {
  position: relative; 
}

.tooltip {
  position: absolute; 
  top: 100%; 
  left: 50%; 
  transform: translateX(-50%); 
  background-color: var(--white-color);
  color: var(--black-color);
  padding: 8px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 1000;
  border-radius: 4px;
  box-shadow: 2px 2px 1px var(--box-shadow-color);
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s ease, visibility 0.1s ease;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
}
