.minimal-chart {
  min-width: 10rem;
  min-height: 4rem;
  height: 100%;

  border-radius: 5px;
  padding: 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  color: var(--extra-grey);
  background-color: var(--white-color);
  box-shadow: 1px 2px 8px var(--box-shadow-color);
}
