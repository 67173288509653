.privacy-notice-notice {
    display: flex;
    flex-direction: column;
    font-family: BoschSans-Regular;
    text-align: justify;
}

.privacy-notice-text-notice {
    font-weight: bold;
    font-size: 32px;
    margin-Bottom: 28px;
    font-family: BoschSans-Bold;
    text-align: justify;
}

.privacy-notice-text {
    font-size: 16px;
    margin-bottom: 30px;
    font-family: BoschSans-Regular;
    text-align: justify;
}

.privacy-notice-text-privacy {
    font-size: 16px;
    margin-bottom: 30px;
    font-family: BoschSans-Regular;
    text-align: justify;
    font-weight: bold;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.privacy-notice-text-subtitle {
    font-size: 16px;
    font-family: BoschSans-Regular;
    text-align: justify;
    margin-bottom: 30px;
    margin-left: 25px;
    font-weight: bold;
}

.privacy-notice-circle {
    list-style: circle;
    margin-left: 65px;
    margin-top: 35px;
    margin-bottom: 55px;
    line-height: 1.5;
}

.privacy-notice-subtitle {
    font-size: 16px;
    margin-bottom: 20px;
    font-family: BoschSans-Regular;
    text-align: justify;
    margin-left: 25px;
}

.privacy-notice-subtitles {
    font-size: 16px;
    font-family: BoschSans-Regular;
    text-align: justify;
    margin-left: 70px;
    margin-top: 20px;
    margin-bottom: 30px;
    font-weight: bold;
}

.privacy-notice-subtitles-regular {
    font-size: 16px;
    margin-bottom: 30px;
    font-family: BoschSans-Regular;
    text-align: justify;
    margin-left: 70px;
    margin-top: 20px;
}

.privacy-notice-texts {
    font-size: 16px;
    margin-bottom: 10px;
    font-family: BoschSans-Regular;
    text-align: justify;
}

.privacy-notice-title-privacy-note {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 25px;
    margin-top: 28px;
    color: black;
    font-family: BoschSans-Bold;
    text-align: justify;
}

@media (max-width: 780px) {
    .privacy-notice-container {
        margin: 30px;
    }
}

@media (min-width: 781px) and (max-width: 1200px) {
    .privacy-notice-container {
        margin: 30px;
    }
}