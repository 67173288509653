.collapsible-section {
  border-radius: 4px;
  margin: 1rem 0;
  padding-left: 1rem;
  border-left: 5px solid var(--disabled-color);
  background-color: var(--background-color);
}

.collapsible-section .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
}

.collapsible-section .header h2 {
  margin: 0;
}

.collapsible-section .icon {
  font-size: 1.5em;
}

.collapse-enter {
  max-height: 0;
  opacity: 0;
}

.collapse-enter-active {
  max-height: 200px;
  opacity: 1;
  transition: max-height 300ms ease-in, opacity 300ms ease-in;
}

.collapse-exit {
  max-height: 200px;
  opacity: 1;
}

.collapse-exit-active {
  max-height: 0;
  opacity: 0;
  transition: max-height 300ms ease-out, opacity 300ms ease-out;
}
