.alert {
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  padding: 15px;
  border-radius: 5px;
  color: var(--white-color);
  min-width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.text {
  font-family: BoschSans-Bold;
  margin: 0 !important;
}

.alert.info {
  background-color: var(--bosch-info-blue);
}

.alert.success {
  background-color: var(--bosch-success-green);
}

.alert.warning {
  background-color: var(--bosch-warning-yellow);
}

.alert.error {
  background-color: var(--bosch-error-red);
}

.closebtn {
  float: right;
  font-weight: bold;
  cursor: pointer;
}

.icon {
  margin-right: 10px;
}

.text {
  display: inline-block;
  vertical-align: middle;
}

.alert {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.alert.show {
  opacity: 1;
  animation: fadeIn 0.5s;
}

.alert.hide {
  opacity: 0;
  animation: fadeOut 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
