.filter-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1px;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 1000px) {
  .filter-container {
    margin-bottom: 2rem;
  }
}

.filter-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
}

.filter-input {
  width: 500px;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding-right: 30px;
  box-sizing: border-box;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #cccccc;
}

.filter-input:focus {
  border-color: #ccc;
  outline: none;
}

.filter-button {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  position: relative;
}

.filter-icon {
  font-size: 18px;
  color: #000 !important;
  font-weight: normal !important;
}

.dropdown-menuo {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 3px;
  background: var(--white-color);
  border: 1px solid var(--extra-grey-light);
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 10rem;
  display: none;
}

.dropdown-menuo.visible {
  display: block;
}

.dropdown-menuo ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-menuo li {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-menuo li:hover {
  background-color: var(--extra-grey-light);
  color: black;
}
@media (max-width: 1000px) {
  .filter-component {
    position: relative !important;
    top: 50px !important;
    right: 245px;
  }

  .filter-input {
    width: calc(100vw - 5rem) !important;
  }
}
