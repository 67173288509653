.ecommerce-card {
    width: 350px;
    height: 400px;
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card-image {
    position: relative;
    width: 100%;
    padding-top: 175px;
    overflow: hidden;
    border: none;
}

.card-image img {
    position: absolute;
    top: 0;
    left: 0;
    height: 175px;
    width: 100%;
    object-fit: cover;
}

.image-footer {
    position: absolute;
    bottom: 0px;
    left: 0;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    display: flex;
    color: #fff;
}

.price {
    width: auto;
    background-color: var(--bosch-accent-turquoise);
    padding: 8px;
    font-weight: bold;
}

.card-content {
    background-color: var(--extra-grey-light);
    width: calc(100% - 16px);
    padding: 8px;
    height: 75%;
}

.card-footer {
    height: 22%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid lightgray;
}

.title {
    color: black;
}

.card-button {
    width: auto;
    height: calc(100% - 8px);
    margin: 4px;
}

.eccomerce-card-render-children div * {
    max-width: 100%;
    max-height: 4.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}