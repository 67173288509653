.card-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
}

.card {
  position: relative; 
  background-color: #ffffff !important;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px 0;
  width: 100%; 
  text-align: left;
}

.card-content {
  background-color: transparent !important;
  border: none;
  padding: 0;
  margin: 0;
}

.card-text h3 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333333;
  margin: 0;
}

.card-text p {
  font-size: 1rem;
  color: #666666;
  margin: 0;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
  transition: all 0.3s ease;
}

.card-icon {
  position: absolute; 
  top: 50%; 
  right: 15px; 
  transform: translateY(-50%); 
  cursor: grab;
  color: #888;
  font-size: 1.5rem; 
}

.card-icon:hover {
  color: #555;
}
