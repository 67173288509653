@font-face {
    font-family: 'Bosch-Icon';
    src: url('../assets/fonts/Bosch-Icon.otf') format('opentype'),
        url('../assets/fonts/Bosch-Icon.ttf') format('truetype');
}

@font-face {
    font-family: 'BoschSans-Light';
    src: url('../assets/fonts/BoschSans-Light.ttf') format('truetype'),
}

@font-face {
    font-family: 'BoschSans-LightItalic';
    src: url('../assets/fonts/BoschSans-LightItalic.ttf') format('truetype'),
}

@font-face {
    font-family: 'BoschSans-Medium';
    src: url('../assets/fonts/BoschSans-Medium.ttf') format('truetype'),
}

@font-face {
    font-family: 'BoschSans-MediumItalic';
    src: url('../assets/fonts/BoschSans-MediumItalic.ttf') format('truetype'),
}

@font-face {
    font-family: 'BoschSans-Regular';
    src: url('../assets/fonts/BoschSans-Regular.ttf') format('truetype'),
}

@font-face {
    font-family: 'BoschSans-RegularItalic';
    src: url('../assets/fonts/BoschSans-RegularItalic.ttf') format('truetype'),
}

@font-face {
    font-family: 'BoschSans-Bold';
    src: url('../assets/fonts/BoschSans-Bold-v5_003.ttf') format('truetype'),
}

@font-face {
    font-family: 'BoschSans-Black';
    src: url('../assets/fonts/BoschSans-Black-v5_003.ttf') format('truetype'),
}