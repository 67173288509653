.language-selector {
    position: relative;
    display: inline-block;
}

.language-selector:hover {
    cursor: pointer;
}

.language-selector .icon {
    cursor: pointer;
    font-size: 1.5em;
}

.language-selector .language-dropdown {
    position: absolute;
    top: 110%;
    left: -50%;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 10000;
    height: 80px;
    overflow-y: scroll;
    width: 120px;
    border-radius: 0;
}

.language-selector .language-dropdown::-webkit-scrollbar {
    width: 2px;
}

.language-selector .language-dropdown .language-button {
    background: none;
    border: none;
    padding: 10px;
    width: 100%;
    text-align: left;
    cursor: pointer;
}

.language-selector .language-dropdown .language-button:hover {
    background-color: #f0f0f0;
}