.wrapper {
    position: fixed;
    bottom: 10px;
    padding: 15px 25px 22px;
    transition: right 0.3s ease;
    display: grid;
    grid-template-columns: 1.5fr 0.3fr;
    gap: 120px;
    margin-left: 20vw;
    margin-right: 20vw;
    justify-content: center;
    background-color: var(--white-color);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, .13);
    width: 60vw;
}

.text-cookies {
    display: flex;
    align-items: center;
    font-family: BoschSans-Regular;
    color: grey;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.text-footer {
    display: flex;
    align-items: center;
    font-family: BoschSans-Regular;
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: none;
    padding: 15px;
}

.buttons {
    display: flex;
    flex-direction: column;

    .button-yes {
        background-color: var(--blue-cookies);
        border-radius: 0;
        color: var(--white-color);
        margin-bottom: 10px;
    }

    .button-no {
        background-color: var(--white-color);
        border-radius: 0;
        color: var(--blue-cookies);
        border: 1px solid var(--blue-cookies);
        margin-bottom: 10px;
    }
}

@media (max-width: 780px) {
    .wrapper {
        display: flex;
        flex-direction: column;
        margin: 0px 10px;
        gap: 0;
        bottom: 5px;
    }

    .text-cookies {
        margin-bottom: 15px;
    }

    .button-no {
        margin-bottom: 1px;
    }
}

@media (min-width: 781px) and (max-width: 1024px) {
    .wrapper {
    display: grid;
    grid-template-columns: 1fr 0.4fr;
    gap: 30px;
    }

}