.landing-page-section {
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.mobile {
    display: none;
}

.box-infos-scrap {
    display: flex;
    justify-content: flex-start;
}

.email-link {
    position: fixed;
    width: 50px;
    height: 50px;
    top: 16rem;
    right: 1px;
    z-index: 1000;
}

.text-country,
.text-percentage {
    font-size: 24px;
    font-weight: 700;
    font-family: BoschSans-Bold;
}

.detals-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.text-percentagem {
    font-size: 24px;
    font-weight: 700;
    display: none;
    font-family: BoschSans-Bold;
}

.text-countrys {
    font-size: 16px;
    font-weight: 400;
    line-Height: 2;
}

.container-img {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 10px 25px;
}

.text-container-percentage {
    font-size: 15px;
}

.text-detals {
    font-size: 12px;
    font-weight: 400;
    margin: 16px;
}

.container-percentage {
    font-size: 80px;
    font-weight: 700;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: BoschSans-Bold;
}


.container-description-percentage {
    display: flex;
    justify-content: center;
    flex-direction: column;
    line-height: 30px;
    text-align: center;
}

.infos-scrap {
    display: grid;
    padding: 16px;
    grid-template-columns: 0.7fr 2fr;
    background-color: var(--grey-light);
    min-height: 130px;
    margin: 16px;
    gap: 30px;
}

.landing-page-section .title-description {
    margin-top: 4rem;
    display: inline;
    font-family: BoschSans-Regular;
    padding: 1rem;
    margin: 0 auto;
}

.landing-page-section .title-description h2 {
    font-size: 48px;
    color: var(--text-color);
    font-weight: 700;
    margin-bottom: 1rem;
    font-family: BoschSans-bold;
}

.landing-page-section .title-description h3 {
    font-family: BoschSans-Bold;
    font-size: 38px;
    color: var(--text-color);
    padding-top: 4rem;
    font-weight: 700;
    letter-spacing: 2px;
}

.description-img {
    font-size: 14px;
}

.description-img-respons {
    font-size: 14px;
    display: none;

}

.detals-text {
    margin-left: 4rem;
    padding: 35px 1px 5px 1px;
}

.fist {
    width: 1150px;
    margin: 0 auto;
    font-family: BoschSans-Regular;
}

.second {
    width: 1250px;
    margin: 0 auto;
    font-family: BoschSans-Regular;
}

.first {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.icon {
    padding-left: 10px;
}

.container-text {
    line-height: 23px
}

.landing-page-description {
    margin-bottom: 30px;
}

strong {
    font-family: BoschSans-Bold;
}

.landing-page-section .title-description p {
    font-family: BoschSans-Regular;
    font-size: 20px;
    color: var(--black-color);
    line-height: 30px;
    text-align: justify;
    font-weight: 400;
    margin-top: 2rem;
}

.titles {
    font-family: BoschSans-Regular;
    font-size: 20px;
    color: var(--black-color);
    line-height: 30px;
    text-align: justify;
    font-weight: 400;
    margin-top: 2rem;
}

.container-highlights {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
    margin: 16px;
}

.image-industry {
    width: 60px;
    height: 60px;
}

.text-industry {
    color: var(--white-color);
    font-size: 30px;
    font-weight: 700;
}

.text-industry-description {
    color: var(--white-color);
    font-size: 20px;
    font-weight: 700;
}

.card-feedback {
    display: flex;
    background-color: var(--grey-light);
    min-height: 270px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 3rem;
}

.text-feedback {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    width: 70%;
    font-family: BoschSans-Bold;
    text-align: justify;
}

.text-feedback-detals {
    font-size: 16px;
    font-weight: 400;
    width: 70%;
    font-family: BoschSans-Regular;
    margin-top: 3rem;
}

.modal-corporate {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background-shadow-color);
}

.modal {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 20px;
    min-width: 100px;
    border-radius: 10px;
    background-color: var(--white-color);
}

.modal-flex {
    display: flex;
    flex-direction: column;
}

.text-modal {
    line-height: 3px;
    font-size: 18px;
    font-weight: 700;
    margin: 0 0px 25px;
}

.description-modal {
    display: flex;
    flex-direction: column;
    margin: 0 0px 25px;
    line-height: 25px;
}

.modal-information {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 80px;
}

.container-representative-click {
    display: flex;
    flex-direction: column;
    background-color: var(--bosch-purple);
    color: var(--white-color);
    margin-bottom: 110px;
    min-height: 110px;
    margin-top: 1.5rem;
}

.container-representative-Mobile {
    display: none;
    flex-direction: column;
    background-color: var(--bosch-purple);
    color: var(--white-color);
    margin-bottom: 110px;
    min-height: 110px;
    margin-top: 1.5rem;
}

.title-click,
.title,
.title-mobile {
    text-decoration: none;
    color: var(--white-color);
}

.title-clicks {
    display: none;
    text-decoration: none;
    color: var(--white-color);
}

.container-representative {
    display: flex;
    flex-direction: row;
    background-color: var(--bosch-purple);
    padding: 10px 10px;
    color: var(--white-color);
    align-items: center;
}

.container-representative-tablet {
    display: none;
    flex-direction: row;
    background-color: var(--bosch-purple);
    padding: 10px 10px;
    color: var(--white-color);
    align-items: center;
    margin-top: 2rem;
}

.marginleft {
    margin-left: 1rem;
}

.font-bold {
    font-family: BoschSans-Bold;
}

.title-representative {
    padding: 15px 10px;
    font-size: 20px;
    font-family: BoschSans-Bold;
    margin-left: 1rem;
}

.font-weight {
    font-weight: 700;
}

.text-tons {
    font-size: 48px;
    font-weight: 700;
    font-family: BoschSans-Bold;
}

.color-white {
    color: var(--white-color);
}

.color-black {
    color: var(--black-color);
}

.text-percentage-hundred-detals {
    display: flex;
    font-weight: 700;
    font-size: 16px;
    font-family: BoschSans-Bold;
    line-height: 20px;
}

.container-tons {
    display: flex;
    background-color: var(--grey-light);
    min-height: 140px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.container-cash {
    display: flex;
    background-color: var(--back-ligth);
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.container-industry {
    display: flex;
    background-color: var(--bosch-accent-purple);
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 140px;
}

.text-tons-description {
    font-weight: 400;
    font-size: 12px;
    font-family: BoschSans-Regular;
    padding: 5px 45px;
}


.cash {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    gap: 10px;
}

.text-rodape {
    font-weight: 400;
    font-size: 20px;
    font-family: BoschSans-Regular;
}

.container-rodape {
    display: flex;
    padding: 18px 5px;
    flex-direction: row;
    align-items: center;
    gap: 10px
}

.description-rodape {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.rodape-respondivo {
    display: none;
    font-weight: 400;
    font-size: 12px;

    span {
        display: block;
        padding: 15px;
    }
}


.rodape {
    display: grid;
    grid-template-columns: 3fr 2fr 1fr 1fr;
    font-weight: 400;
    font-size: 12px;
    padding: 30px 1px;
    align-items: center;
    gap: 2px;
}

.container-representative-resposivo {
    display: flex;
    font-family: BoschSans-Bold;
    font-weight: 700;
    flex-direction: row;
    background-color: var(--bosch-purple);
    padding: 10px 10px;
    color: var(--white-color);
    align-items: center;
    margin-top: 15px;
}

.control {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.control img {
    width: 100%;
    height: auto;
}

.superior {
    display: flex;
    width: 278px;
    height: 234px;
    padding-left: 30px;
    background: #2E3033;
    font-size: 16px;
    color: #fff;
    position: absolute;
    bottom: 0px;
    left: 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
}

.superior-country {
    display: none;
    width: 100%;
    height: 134px;
    background: #2E3033;
    font-size: 16px;
    color: #fff;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    margin: 1px;
    padding: 4px;
}

.img-principal {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title-main,
.detals-ones {
    display: none;
}

@media (min-width: 781px) and (max-width: 1024px) {

    .none {
        display: none;
    }

    .container-industry,
    .container-cash {
        flex-direction: row;
        gap: 5px;
    }

    .text-percentage {
        font-size: 20px;
    }

    .container-highlights {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .detals-flex,
    .superior {
        display: none;
    }

    .title-initial,
    .flex {
        display: none;
    }

    .container-representative-click {
        display: none;
    }

    .title-main,
    .detals-ones {
        display: flex;
    }

    .container-representative-tablet,
    .superior-country {
        display: flex;
    }

    .landing-page-section .title-description h2 {
        font-size: 32px;
    }

    .infos-scrap {
        display: block;
    }

    .container-percentage,
    .text-tons {
        font-size: 48px;
        justify-content: center;
    }

    .mobile {
        display: inline;
    }

    .container-percentage {
        font-size: 64px;
    }

    .text-percentagem-description {
        font-size: 12px;
    }

    .container-representative-Mobile {
        display: flex;
    }

}


@media (max-width: 780px) {

    .detals-ones {
        display: flex;
    }


    .infos-scrap,
    .container-img {
        display: block;
    }

    .landing-page-section .title-description h2,
    .text-rodape {
        font-size: 20px;
    }

    .landing-page-section .title-description p {
        font-size: 16px;
    }

    .container-text,
    .text-percentage,
    .text-feedback-detals,
    .text-percentage-hundred-detals,
    .text-countrys,
    .title-click,
    .title,
    .title-mobile,
    .title-clicks,
    .container-description-percentage span {
        font-size: 12px;
    }

    .title-mobile {
        color: #fff;
    }

    .landing-page-section .title-description h3 {
        font-size: 24px;
    }

    .img-principal {
        width: 100%;
        height: 50vh;
        object-fit: cover;
    }

    .titles {
        font-size: 12px;
    }

    .container-percentage,
    .text-tons {
        font-size: 32px;
        justify-content: center;
    }

    .container-highlights {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

    }

    .detals-text {
        margin-left: 0;
    }

    .text-feedback,
    .text-country {
        font-size: 16px;
    }

    .icon-email {
        width: 35px;
        height: 35px;
    }

    .email-link {
        display: flex;
        justify-content: flex-end;
    }

    .description-img-respons {
        font-size: 12px;
        display: flex;
        padding: 5px 1px 10px 1px;
    }

    .text-percentagem {
        font-size: 24px;
        font-weight: 700;
        display: flex;
        justify-content: center;
    }

    .text-percentage {
        display: none;
    }

    .card-feedback {
        padding: 40px 20px;
    }

    .superior,
    .rodape,
    .description-img,
    .container-representative-click,
    .flex {
        display: none;
    }

    .superior-country {
        display: flex;
    }

    .container-representative-Mobile {
        display: flex;
    }

    .rodape-respondivo,
    .mobile {
        display: inline;
    }

    .title-click {
        display: none;
    }

    .title-clicks {
        display: inline;
    }

    .modal-information {
        display: flex;
        flex-direction: column;
        gap: 0px;

    }
}