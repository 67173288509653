.nav-item-label {
  font-size: large;
}

.nav-item-label-active {
  color: var(--bosch-accent-blue);
}

.header-heigth-limiter {
  max-height: 7.5rem !important;
  overflow: hidden;
  width: calc(100% - 48px);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu-option-to-hover:hover * {
  color: rgb(0, 123, 192) !important;
  cursor: pointer;
}


.spark-button--integrated:hover {
  color: red;
}

.header-content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-first-custom-header-label {
  margin-left: -32px;
  display: flex;
  align-items: center;
  margin-top: -42px;
}

@media (max-width: 768px) {
  .header-session-text-area {
    display: none;
  }

  .header-first-custom-header-label {
    margin-left: -32px;
  }
}

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.menu-items {
  display: flex;
  gap: 20px;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-toggle span {
  height: 2px;
  width: 25px;
  background-color: var(--extra-grey);
  margin: 3px 0;
  transition: 0.4s;
}

@media (max-width: 1150px) {
  .menu-toggle {
    display: flex;
  }

  .menu-items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    background-color: var(--white-color);
    box-shadow: 3px 0 5px var(--background-shadow-color);
    padding: 20px;
    z-index: 1000;
    transition: transform 0.3s ease-in-out, opacity 0.4s ease-in-out;
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
    border-top: 6px solid;
    border-image: linear-gradient(to right, var(--linear-gradient-bosch-colors)) 1 0 0 0;
  }

  .menu.active .menu-items {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}

.overlay {
  display: none;
}

.menu.active .overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}