:root {
  --white-color: #ffffff;
  --white-color-5: #fafafa;
  --black-color: #000000;
  --extra-grey: #5a5a5a;
  --back-ligth: #2e3033;
  --extra-grey-medium: #9e9e9e;
  --extra-grey-light: #f0f0f0;
  --grey-light: #eff1f2;
  --blue-cookies: #163b67;

  --background-color: #ffffff;
  --text-color: #000000;
  --divider-color: #b7b7b7;
  --secondary-text-color: #71767c;
  --placeholder-text-color: #bcbcbc;
  --disabled-color: #cecece;

  --primary-color: var(--bosch-accent-turquoise);
  --secondary-color: #1495dc;

  --bosch-purple: #791d73;
  --bosch-accent-purple: #9e2896;
  --bosch-accent-blue: #007bc0;
  --bosch-accent-turquoise: #18837e;
  --bosch-accent-turquoise-opacity10: #18837e2f;
  --bosch-accent-green: #00884a;

  --bosch-info-blue: #007bc0;
  --bosch-info-blue-opacity10: #007ac021;
  --bosch-success-green: #00884a;
  --bosch-success-green-opacity10: #00884b21;
  --bosch-error-red: #ed0007;
  --bosch-error-red-opacity10: #ed000821;
  --bosch-warning-yellow: #ffcf00;
  --bosch-warning-yellow-dark: #caaa1c;
  --bosch-warning-yellow-opacity10: #ffd00021;

  --light-green: #3cd895;
  --light-red: rgb(255, 167, 167);

  --border-fields: #e1e1e1;
  --box-shadow-color: #6e6e6e1a;
  --background-shadow-color: #0000002b;

  --linear-gradient-bosch-colors: #942331 0%, #931813 3.61%, #b12638 8.46%,
    #af1917 12.37%, #b11917 12.57%, #cb1517 15.33%, #d51317 16.97%,
    #ce1b24 17.55%, #bb2742 18.76%, #9b326b 20.5%, #88357f 21.42%,
    #853580 24.36%, #6e368c 26.38%, #3a4190 29.11%, #14387f 32.42%,
    #2b2f75 39.26%, #312a6f 41.81%, #2a3281 47.56%, #283587 49.4%,
    #1d61a1 55.81%, #2169a5 56.67%, #3d8fb6 60.85%, #53a7be 63.93%,
    #5bb0c0 65.56%, #53adbe 67.48%, #36a3ba 69.93%, #0095b3 72.66%,
    #008bae 74.26%, #009490 79.8%, #00a24c 88.75%, #00937d 100%;
}

[data-theme="dark"] {
  --white-color: #ffffff;
  --white-color-5: #fafafa;
  --black-color: #000000;
  --extra-grey: #5a5a5a;
  --extra-grey-medium: #808080;
  --extra-grey-light: #f0f0f0;

  --background-color: #000000;
  --text-color: #ffffff;
  --secondary-text-color: #71767c;
  --placeholder-text-color: #dcdcdc70;
  --disabled-color: #cecece;

  --primary-color: var(--bosch-accent-turquoise);
  --secondary-color: #1495dc;

  --bosch-purple: #791d73;
  --bosch-accent-purple: #9e2896;
  --bosch-accent-blue: #007bc0;
  --bosch-accent-turquoise: #18837e;
  --bosch-accent-turquoise-opacity10: #18837e2f;
  --bosch-accent-green: #00884a;

  --bosch-info-blue: #007bc0;
  --bosch-info-blue-opacity10: #007ac021;
  --bosch-success-green: #00884a;
  --bosch-success-green-opacity10: #00884b21;
  --bosch-error-red: #ed0007;
  --bosch-error-red-opacity10: #ed000821;
  --bosch-warning-yellow: #ffcf00;
  --bosch-warning-yellow-opacity10: #ffd00021;

  --light-green: #3cd895;
  --light-red: rgb(255, 167, 167);

  --border-fields: #bbbbbb;
  --box-shadow-color: #6e6e6e1a;
  --background-shadow-color: #6e6e6e1a;

  --linear-gradient-bosch-colors: #942331 0%, #931813 3.61%, #b12638 8.46%,
    #af1917 12.37%, #b11917 12.57%, #cb1517 15.33%, #d51317 16.97%,
    #ce1b24 17.55%, #bb2742 18.76%, #9b326b 20.5%, #88357f 21.42%,
    #853580 24.36%, #6e368c 26.38%, #3a4190 29.11%, #14387f 32.42%,
    #2b2f75 39.26%, #312a6f 41.81%, #2a3281 47.56%, #283587 49.4%,
    #1d61a1 55.81%, #2169a5 56.67%, #3d8fb6 60.85%, #53a7be 63.93%,
    #5bb0c0 65.56%, #53adbe 67.48%, #36a3ba 69.93%, #0095b3 72.66%,
    #008bae 74.26%, #009490 79.8%, #00a24c 88.75%, #00937d 100%;
}
