input {
  padding: 8px;
}

.card-dealer .left img {
  min-height: 5rem;
  max-height: 95px;
  width: 146px;
  margin-right: 1.5rem;
}
