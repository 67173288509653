.coming-soon-section {
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.coming-soon-section img {
    width: 100%;
}

.coming-soon-section .title-description {
    display: inline;
    font-family: BoschSans-Regular;
    padding: 1rem;
}

.coming-soon-section .title-description h2 {
    font-size: 46px;
    color: var(--text-color);
    font-weight: bold;
    margin-bottom: 1rem;
}

.coming-soon-section .title-description p {
    font-size: 20x;
    color: var(--extra-grey);
}

@media (max-width: 1150px) {
    .coming-soon-section {
        flex-direction: column;
    }

    .coming-soon-section img {
        width: 100vw;
    }

    .coming-soon-section .title-description h2 {
        font-size: 9vw;
    }

    .coming-soon-section .title-description p {
        font-size: 5vw;
    }
}